<template>
  <v-container fluid class="width-80" v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Company Settings
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>

    <v-card flat class="custom-grey-border remove-border-radius">
      <v-card-title class="headline grey lighten-4">
        <v-row>
          <v-col md="6" class="py-0 my-auto">
            <h3
              class="font-weight-700 custom-headline color-custom-blue"
              style="margin: 3.5px 0px"
            >
              Quote of the day
            </h3>
          </v-col>
          <template v-if="getPermission('setting:update')">
            <v-col md="6" class="text-right py-0">
              <v-btn
                small
                class="text-white"
                color="cyan"
                v-on:click="updateCompanySetting"
                :disabled="pageLoading"
                :loading="pageLoading"
              >
                <span class="font-size-16 font-weight-600">Update</span>
              </v-btn>
            </v-col>
          </template>
        </v-row>
      </v-card-title>
      <v-card-text
        class="p-6 font-size-16"
        :class="{ 'pointer-events-none': !getPermission('setting:update') }"
      >
        <v-row>
          <v-col md="12" class="pb-2 pt-0">
            <v-menu
              transition="slide-y-transition"
              bottom
              content-class="custom-menu-list"
              offset-y
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  tile
                  class="mb-2 custom-bold-button white--text"
                  color="cyan"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ month_name.label }} <v-icon right>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(row, index) in month_names"
                  :key="index"
                  v-on:click="select_month(row)"
                  link
                >
                  <v-list-item-title class="font-weight-500 font-size-14">
                    {{ row.label }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
            <v-progress-linear
              color="cyan"
              indeterminate
              height="6"
              v-if="content_loading"
            ></v-progress-linear>
            <table
              width="100%"
              style="table-layout: fixed"
              class="alternate-row"
            >
              <thead>
                <tr>
                  <th class="py-2 pl-5 font-weight-500 cyan--text" width="17%">
                    Date
                  </th>
                  <th
                    class="py-2 pl-5 font-weight-500 cyan--text"
                    width="1.5%"
                  ></th>
                  <th class="py-2 pl-5 font-weight-500 cyan--text" width="65%">
                    Quote
                  </th>
                  <th
                    class="py-2 pl-5 font-weight-500 cyan--text"
                    width="1.5%"
                  ></th>
                  <th class="py-2 pl-5 font-weight-500 cyan--text" width="15%">
                    Image
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, index) in quote_dates"
                  :key="index"
                  :class="{ 'cyan lighten-5': isToday(row.date) }"
                >
                  <td valign="top" class="py-2 px-2" width="17%">
                    <date-picker
                      solo
                      v-model="row.date"
                      :default-date="row.default"
                      :min-date="today_date"
                      :page-loading="pageLoading"
                    ></date-picker>
                    <v-chip
                      v-if="isToday(row.date)"
                      label
                      color="orange darken-4 white--text"
                      depressed
                      class="mt-2"
                      >TODAY</v-chip
                    >
                  </td>
                  <td valign="top" class="py-2 px-2" width="1.5%"></td>
                  <td valign="top" class="py-2 px-2" width="65%">
                    <v-textarea
                      dense
                      filled
                      label="Enter quote..."
                      solo
                      flat
                      hide-details
                      v-model="row.quote"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                      :rows="1"
                    ></v-textarea>
                  </td>
                  <td valign="top" class="py-2 px-2" width="1.5%"></td>
                  <td valign="top" class="py-2 px-2" width="15%">
                    <v-hover v-slot="{ hover }">
                      <div style="position: relative">
                        <v-icon
                          v-if="hover"
                          :disabled="image_loading"
                          v-on:click="selectImage(row, index)"
                          color="cyan white--text"
                          style="
                            position: absolute;
                            right: 10px;
                            top: 10px;
                            z-index: 99;
                          "
                        >
                          mdi-refresh
                        </v-icon>
                        <v-img
                          height="150px"
                          contain
                          lazy-src="https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg"
                          :src="
                            row.image
                              ? row.image
                              : 'https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg'
                          "
                        ></v-img>
                      </div>
                    </v-hover>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <div class="d-none">
      <v-file-input
        ref="uploadImage"
        v-on:change="updateImage($event)"
        accept="image/png, image/jpeg, image/jpg"
      ></v-file-input>
    </div>

    <v-card flat class="mt-4 custom-grey-border remove-border-radius">
      <v-card-title class="headline grey lighten-4">
        <v-row>
          <v-col md="6" class="py-0 my-auto">
            <h3
              class="font-weight-700 custom-headline color-custom-blue"
              style="margin: 3.5px 0px"
            >
              Company Details
            </h3>
          </v-col>
          <template v-if="getPermission('setting:update')">
            <v-col md="6" class="text-right py-0">
              <v-btn
                small
                class="text-white"
                color="cyan"
                v-on:click="updateCompanySetting"
                :disabled="pageLoading"
                :loading="pageLoading"
              >
                <span class="font-size-16 font-weight-600">Update</span>
              </v-btn>
            </v-col>
          </template>
        </v-row>
      </v-card-title>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow"
        style="max-height: 70vh; position: static"
      >
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12" class="pb-4">
              <v-text-field
                v-model.trim="setting.company_name"
                dense
                filled
                label="Company name"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="12" class="pb-4">
              <v-text-field
                v-model.trim="setting.phone_number"
                dense
                filled
                label="Phone number"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="12" class="pb-4">
              <v-text-field
                v-model.trim="setting.whatsapp_number"
                dense
                filled
                label="Whatsapp number"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="12" class="pb-4">
              <v-text-field
                v-model.trim="setting.website_url"
                dense
                filled
                label="Website URL"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="12" class="pb-4">
              <v-text-field
                v-model.trim="setting.email_address"
                dense
                filled
                label="Email address"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="6" class="pb-4">
              <v-text-field
                v-model.trim="setting.street_1"
                dense
                filled
                label="Street 1"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="6" class="pb-4">
              <v-text-field
                v-model.trim="setting.street_2"
                dense
                filled
                label="Street 2"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="6" class="pb-4">
              <v-text-field
                v-model.trim="setting.landmark"
                dense
                filled
                label="Landmark"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="6" class="pb-4">
              <v-text-field
                v-model.trim="setting.zip_code"
                dense
                filled
                label="Zip code"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="12" class="mt-4 pb-4 custom-border-top">
              <h3
                class="font-weight-700 custom-headline color-custom-blue py-4"
              >
                Regional Settings
              </h3>
              <v-row>
                <v-col md="4">
                  <v-select
                    v-model="setting.date_format"
                    :items="date_format_list"
                    label="Date format"
                    solo
                    flat
                    dense
                    filled
                    hide-details
                    item-color="cyan"
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    color="cyan"
                    class="py-2"
                  ></v-select>
                </v-col>
                <v-col md="4">
                  <v-select
                    v-model="setting.time_format"
                    :items="time_format_list"
                    label="Time format"
                    solo
                    flat
                    dense
                    filled
                    hide-details
                    item-color="cyan"
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    color="cyan"
                    class="py-2"
                  ></v-select>
                </v-col>
                <v-col md="4">
                  <v-select
                    v-model="setting.weekday"
                    :items="weekdayList"
                    label="First day of the week"
                    solo
                    flat
                    dense
                    filled
                    hide-details
                    item-color="cyan"
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    color="cyan"
                    class="py-2"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="12" class="mt-4 pb-4 custom-border-top">
              <h3
                class="font-weight-700 custom-headline color-custom-blue py-4"
              >
                Business Hours
              </h3>
              <div>
                <table class="width-100">
                  <tbody>
                    <tr
                      v-for="(day, index) in weekDays"
                      :key="index"
                      :class="{
                        'business-hours-disabled': !day.status,
                      }"
                    >
                      <td width="200" class="font-weight-600 font-size-16">
                        {{ day.title }}
                      </td>
                      <td width="200">
                        <v-switch
                          v-model.trim="day.status"
                          inset
                          hide-details
                          color="cyan"
                        ></v-switch>
                      </td>
                      <td
                        class="px-4"
                        :class="{ 'pointer-events-none': !day.status }"
                      >
                        <TimePickerTemplate
                          v-on:update:time-picker="
                            updateTime('start', index, $event)
                          "
                          :defaultTime.sync="day.start_time"
                          placeholder="Start Time"
                        ></TimePickerTemplate>
                      </td>
                      <td
                        class="px-4"
                        :class="{ 'pointer-events-none': !day.status }"
                      >
                        <TimePickerTemplate
                          v-on:update:time-picker="
                            updateTime('end', index, $event)
                          "
                          :defaultTime.sync="day.end_time"
                          placeholder="End Time"
                        ></TimePickerTemplate>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </perfect-scrollbar>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import TimePickerTemplate from "@/view/pages/partials/Timepicker";
import DatePicker from "@/view/pages/partials/Datepicker";
import { QUERY, PATCH, UPLOAD } from "@/core/services/store/request.module";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "profile-company-setting",
  data() {
    return {
      pageLoading: true,
      month_names: [],
      month_name: {},
      image_row: {},
      image_index: null,
      setting: {
        quote: null,
        expiry_date: null,
        company_name: null,
        phone_number: null,
        whatsapp_number: null,
        website_url: null,
        email_address: null,
        street_1: null,
        street_2: null,
        landmark: null,
        zip_code: null,
        date_format: null,
        time_format: null,
        weekday: null,
      },
      time_format_list: [
        {
          value: "HH:mm",
          text: "24 Hour (" + moment().format("HH:mm") + ")",
        },
        {
          value: "hh:mm A",
          text: "12 Hour (" + moment().format("hh:mm A") + ")",
        },
      ],
      date_format_list: [
        {
          value: "MMMM Do YYYY",
          text: moment().format("MMMM Do YYYY"),
        },
        {
          value: "Do MMMM YYYY",
          text: moment().format("Do MMMM YYYY"),
        },
        {
          value: "MMM Do YY",
          text: moment().format("MMM Do YY"),
        },
        {
          value: "Do MMM YY",
          text: moment().format("Do MMM YY"),
        },
        {
          value: "DD/MM/YYYY",
          text: moment().format("DD/MM/YYYY"),
        },
        {
          value: "MM/DD/YYYY",
          text: moment().format("MM/DD/YYYY"),
        },
        {
          value: "YYYY-DD-MM",
          text: moment().format("YYYY-DD-MM"),
        },
      ],
      weekdayList: [
        {
          value: 1,
          text: "Sunday",
        },
        {
          value: 2,
          text: "Monday",
        },
      ],
      weekDays: [],
      quote_dates: [],
      today_date: null,
      expiry_date: null,
      image_loading: false,
      content_loading: false,
    };
  },
  methods: {
    selectImage(row, index) {
      this.image_row = row;
      this.image_index = index;
      this.$refs["uploadImage"].$refs["input"].click();
    },
    updateImage(file) {
      if (!file) {
        return false;
      }
      let formData = new FormData();
      formData.append("image", file, file.name);
      formData.append("date", this.image_row.date);
      this.image_loading = true;
      this.$store
        .dispatch(UPLOAD, { url: "setting/company/image", data: formData })
        .then(({ data }) => {
          this.quote_dates = data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.image_loading = false;
          this.$refs["uploadImage"].reset();
        });
    },
    getCompanySetting(date) {
      const _this = this;
      _this.content_loading = true;
      _this.$store
        .dispatch(QUERY, { url: "setting/company", data: { date } })
        .then(({ data }) => {
          _this.setting = data;
          if (data.month) {
            _this.month_name = {
              label: moment(data.month).format("MMMM YYYY"),
              value: moment(data.month).format("YYYY-MM-DD"),
            };
            _this.quote_dates = data.quotes;
          }
          _this.weekDays = data.business_hours;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
          _this.content_loading = false;
        });
    },
    updateCompanySetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.setting.business_hours = _this.weekDays;
      _this.setting.quote_dates = _this.quote_dates;
      _this.setting.month = _this.month_name.value;
      _this.$store
        .dispatch(PATCH, { url: "setting/company", data: _this.setting })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateTime(type, index, value) {
      if (this.weekDays[index]) {
        if (type == "start") {
          this.weekDays[index].start_time = value;
        }
        if (type == "end") {
          this.weekDays[index].end_time = value;
        }
      }
    },
    isToday(param) {
      let today_date = moment().format("YYYY-MM-DD");
      return param == today_date;
    },
    select_month(param) {
      const date = moment(param.value).format("YYYY-MM-DD");
      this.quote_dates = [];
      this.getCompanySetting(date);
    },
  },
  components: {
    DatePicker,
    TimePickerTemplate,
  },
  created() {
    this.today_date = moment().add(1, "day").format("YYYY-MM-DD");

    this.expiry_date = moment().add(1, "month").format("YYYY-MM-DD");

    this.month_names = [];

    let today = moment();

    for (let i = 0; i < 6; i++) {
      this.month_names.push({
        label: today.format("MMMM YYYY"),
        value: today.format("YYYY-MM-DD"),
      });
      today = today.add(1, "month");
    }

    /*this.select_month({
      label: moment().format("MMMM YYYY"),
      value: moment().format("YYYY-MM-DD"),
    });*/
  },
  mounted() {
    this.getCompanySetting();
  },
};
</script>
