<template>
  <v-container fluid class="width-80" v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Quotation
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 70vh; position: static"
    >
      <v-card flat class="custom-grey-border remove-border-radius">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Quotation #
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="6" class="text-right py-0">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateQuotationSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="3" class="pb-4">
              <label>Prefix</label>
              <v-text-field
                v-model.trim="setting.prefix"
                dense
                filled
                label="Prefix"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="3" class="pb-4">
              <label>Next Number</label>
              <v-text-field
                v-model.trim="setting.next_number"
                dense
                filled
                label="Next Number"
                solo
                flat
                type="number"
                min="0"
                max="999"
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="3" class="pb-4">
              <label>Number Length</label>
              <v-text-field
                v-model.trim="setting.number_length"
                dense
                filled
                label="Number Length"
                solo
                flat
                type="number"
                min="0"
                max="15"
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="3" class="pb-4">
              <label>Open Days</label>
              <v-text-field
                v-model.trim="setting.quotation_open_days"
                dense
                filled
                label="Open Days"
                solo
                flat
                type="number"
                min="0"
                max="15"
                hide-details
                v-mask="'####'"
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Quotation Notes
              </h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="p-6 font-size-16">
          <v-row>
            <v-col md12>
              <table width="100%">
                <thead>
                  <tr>
                    <th align="center" class="text-center py-2" width="3%">
                      #
                    </th>
                    <th align="center" width="10%" class="text-center py-2">
                      Type
                    </th>
                    <th width="20%" class="py-2">Label</th>
                    <th class="py-2">Description</th>
                    <th class="py-2" width="10%"></th>
                  </tr>
                </thead>
                <tbody v-if="note_listing.length">
                  <tr
                    v-for="(row, index) in note_listing"
                    :key="index"
                    class="border-top"
                  >
                    <td align="center" class="py-2">{{ index + 1 }}</td>
                    <td align="center" class="py-2">
                      <v-chip
                        small
                        class="white--text text-uppercase"
                        :class="`quotation-note-type-${row.type}`"
                        >{{ row.type }}</v-chip
                      >
                      <template v-if="row.primary">
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              color="green darken-1"
                              class="ml-2"
                              >mdi-record</v-icon
                            >
                          </template>
                          <span>Marked as Default</span>
                        </v-tooltip>
                      </template>
                    </td>
                    <td class="py-2">{{ row.label }}</td>
                    <td class="py-2">
                      <span v-html="row.listing_desc"></span>
                    </td>
                    <td align="center" class="py-2">
                      <v-btn
                        small
                        icon
                        color="cyan"
                        v-on:click="edit_note_dialog(row)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn class="ml-4" small icon color="red">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
                <tfoot v-else>
                  <tr class="border-top border-bottom">
                    <td colspan="5" class="py-4">
                      <p
                        class="m-0 row-not-found text-center font-weight-500 font-size-16"
                      >
                        <img
                          :src="$assetURL('media/error/empty.png')"
                          class="row-not-found-image"
                        />
                        Uhh... Nothing Found.
                      </p>
                    </td>
                  </tr>
                </tfoot>
              </table>
              <v-btn
                small
                v-on:click="open_note_dialog()"
                class="mt-4 text-white"
                color="cyan"
                :disabled="pageLoading"
                :loading="pageLoading"
              >
                <span class="font-size-16 font-weight-600">Add Note</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="false">
            <v-col md="6" class="pb-4">
              <v-textarea
                v-model.trim="setting.admin_note"
                auto-grow
                dense
                filled
                label="Admin"
                solo
                flat
                :disabled="pageLoading"
                :loading="pageLoading"
                row-height="30"
                color="cyan"
              ></v-textarea>
            </v-col>
            <v-col md="6" class="pb-4">
              <v-textarea
                v-model.trim="setting.client_note"
                auto-grow
                dense
                filled
                label="Client"
                solo
                flat
                :disabled="pageLoading"
                :loading="pageLoading"
                row-height="30"
                color="cyan"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Quotation Terms &amp; Conditions
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="6" class="text-right py-0">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateQuotationSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12" class="pb-4">
              <v-textarea
                v-model.trim="setting.terms_conditions"
                auto-grow
                dense
                filled
                label="Terms &amp; Conditions"
                solo
                flat
                :disabled="pageLoading"
                :loading="pageLoading"
                row-height="30"
                color="cyan"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </perfect-scrollbar>
    <note-dialog :common-dialog="note_dialog" :dialog-width="dialogWidth">
      <template v-slot:title> Update Note </template>
      <template v-slot:body>
        <v-container>
          <v-form
            ref="noteForm"
            v-model="noteForm"
            lazy-validation
            v-on:submit.stop.prevent="update_or_create_note()"
          >
            <table width="100%">
              <tr>
                <th valign="middle" width="15%" class="py-2">Type</th>
                <td class="py-2">
                  <v-radio-group
                    :disabled="pageLoading || formLoading"
                    v-model="note.type"
                    row
                    hide-details
                    class="mt-0"
                  >
                    <v-radio
                      color="cyan"
                      label="Internal"
                      value="internal"
                    ></v-radio>
                    <v-radio
                      color="cyan"
                      label="Client (visible on PDF)"
                      value="client"
                    ></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr>
                <th valign="middle" class="py-2">Label</th>
                <td class="py-2">
                  <v-text-field
                    placeholder="Label"
                    dense
                    solo
                    flat
                    :rules="[(v) => !!v || 'Label is required']"
                    color="cyan"
                    v-model="note.label"
                    hide-details
                    :disabled="pageLoading || formLoading"
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <th valign="top" class="pt-4">Description</th>
                <td class="py-2">
                  <tiny-mce
                    :disabled="formLoading"
                    v-model="note.description"
                  />
                </td>
              </tr>
              <tr>
                <th valign="top" class="pt-4">Default?</th>
                <td class="py-2">
                  <v-radio-group
                    hide-details
                    class="mx-0 my-0 py-0 px-0"
                    v-model="note.primary"
                    row
                  >
                    <v-radio color="cyan" label="Yes" :value="1"></v-radio>
                    <v-radio color="cyan" label="No" :value="0"></v-radio>
                  </v-radio-group>
                </td>
              </tr>
            </table>
          </v-form>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          class="custom-bold-button"
          :disabled="formLoading"
          v-on:click="note_dialog = false"
          >Cancel</v-btn
        >
        <v-btn
          class="custom-bold-button"
          :disabled="formLoading"
          :loading="formLoading"
          v-on:click="update_or_create_note()"
          color="cyan white--text"
          >Save</v-btn
        >
      </template>
    </note-dialog>
  </v-container>
</template>

<script>
import { GET, PATCH } from "@/core/services/store/request.module";
import Dialog from "@/view/pages/partials/Dialog";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";

export default {
  name: "profile-quotation-setting",
  data() {
    return {
      pageLoading: true,
      formLoading: false,
      noteForm: true,
      setting: {
        prefix: null,
        next_number: null,
        number_length: null,
        quotation_open_days: null,
        terms_conditions: null,
        admin_note: null,
        client_note: null,
      },
      note: {
        id: null,
        type: "internal",
        label: null,
        primary: 0,
        description: null,
      },
      note_dialog: false,
      note_listing: [],
    };
  },
  methods: {
    open_note_dialog() {
      this.note = {
        id: null,
        type: "internal",
        label: null,
        primary: 0,
        description: null,
      };
      this.$nextTick(function () {
        this.note_dialog = true;
      });
    },
    edit_note_dialog(row) {
      this.note = {
        id: row.id,
        type: row.type,
        label: row.label,
        primary: row.primary,
        description: row.description,
      };
      this.$nextTick(function () {
        this.note_dialog = true;
      });
    },
    update_or_create_note() {
      let _this = this;

      const vStatus = _this.$refs.noteForm.validate();

      if (!vStatus) {
        return false;
      }

      _this.formLoading = true;

      setTimeout(function () {
        _this.$store
          .dispatch(PATCH, { url: "setting/quotation/note", data: _this.note })
          .then(({ data }) => {
            _this.note_listing = data;
            _this.note_dialog = false;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
          });
      }, 500);
    },
    getQuotationNote() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/quotation/note" })
        .then(({ data }) => {
          _this.note_listing = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getQuotationSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/quotation" })
        .then(({ data }) => {
          _this.setting = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateQuotationSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, { url: "setting/quotation", data: _this.setting })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  components: {
    "tiny-mce": TinyMCE,
    "note-dialog": Dialog,
  },
  mounted() {
    this.getQuotationSetting();
    this.getQuotationNote();
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
};
</script>
