<template>
  <v-container fluid class="width-80" v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Email Template
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 70vh; position: static"
    >
      <v-card
        v-if="emailLeadTemplates.length > 0"
        flat
        class="custom-grey-border remove-border-radius my-4"
      >
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                LEAD NOTIFICATIONS
              </h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="p-6 font-size-16">
          <table class="width-100">
            <tbody v-if="emailLeadTemplates.length > 0">
              <template v-for="(template, index) in emailLeadTemplates">
                <tr
                  :key="'title-' + index"
                  :class="{ 'custom-border-top': index > 0 }"
                >
                  <td
                    colspan="2"
                    class="pb-4 font-weight-600 font-size-16 color-custom-blue"
                    :class="{ 'py-4': index > 0 }"
                  >
                    {{ template.template }}

                    <v-chip
                      class="ma-2"
                      :color="template.activated ? 'green' : 'red lighten-1'"
                      label
                      outlined
                      small
                    >
                      <template v-if="template.activated">Active</template>
                      <template v-else>In-Active</template>
                    </v-chip>
                  </td>
                </tr>
                <tr :key="'description-' + index">
                  <td>{{ template.description_text }}</td>
                  <td class="text-right pb-4">
                    <v-btn
                      text
                      v-on:click="pushToUpdate(template.id)"
                      color="cyan"
                      class="font-size-16"
                      >Edit</v-btn
                    >
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot v-else>
              <template v-if="pageLoading">
                <tr>
                  <td colspan="2">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-200px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
                <tr>
                  <td width="100%">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-300px"
                    ></v-skeleton-loader>
                  </td>
                  <td class="text-right">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-100px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="2">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no email template at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tfoot>
          </table>
        </v-card-text>
      </v-card>
      <v-card
        v-if="
          emailPackageTemplates.length > 0 || emailQuotationTemplates.length > 0
        "
        flat
        class="custom-grey-border remove-border-radius"
      >
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                GETTING THE WORK
              </h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="p-6 font-size-16">
          <table class="width-100">
            <tbody
              v-if="
                emailPackageTemplates.length > 0 ||
                emailQuotationTemplates.length > 0
              "
            >
              <template v-for="(template, index) in emailPackageTemplates">
                <tr
                  :key="'package-title-' + index"
                  :class="{ 'custom-border-top': index > 0 }"
                >
                  <td
                    colspan="2"
                    class="pb-4 font-weight-600 font-size-16 color-custom-blue"
                    :class="{ 'py-4': index > 0 }"
                  >
                    {{ template.template }}

                    <v-chip
                      class="ma-2"
                      :color="template.activated ? 'green' : 'red lighten-1'"
                      label
                      outlined
                      small
                    >
                      <template v-if="template.activated">Active</template>
                      <template v-else>In-Active</template>
                    </v-chip>
                  </td>
                </tr>
                <tr :key="'package-description-' + index">
                  <td>{{ template.description_text }}</td>
                  <td class="text-right pb-4">
                    <v-btn
                      v-on:click="pushToUpdate(template.id)"
                      text
                      color="cyan"
                      class="font-size-16"
                      >Edit</v-btn
                    >
                  </td>
                </tr>
              </template>
              <template v-for="(template, index) in emailQuotationTemplates">
                <tr :key="'title-' + index" class="custom-border-top">
                  <td
                    colspan="2"
                    class="py-4 font-weight-600 font-size-16 color-custom-blue"
                  >
                    {{ template.template }}

                    <v-chip
                      class="ma-2"
                      :color="template.activated ? 'green' : 'red lighten-1'"
                      label
                      outlined
                      small
                    >
                      <template v-if="template.activated">Active</template>
                      <template v-else>In-Active</template>
                    </v-chip>
                  </td>
                </tr>
                <tr :key="'description-' + index">
                  <td>{{ template.description_text }}</td>
                  <td class="text-right pb-4">
                    <v-btn
                      v-on:click="pushToUpdate(template.id)"
                      text
                      color="cyan"
                      class="font-size-16"
                      >Edit</v-btn
                    >
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot v-else>
              <template v-if="pageLoading">
                <tr>
                  <td colspan="2">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-200px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
                <tr>
                  <td width="100%">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-300px"
                    ></v-skeleton-loader>
                  </td>
                  <td class="text-right">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-100px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="2">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no email template at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tfoot>
          </table>
        </v-card-text>
      </v-card>
      <v-card
        v-if="false"
        flat
        class="custom-grey-border remove-border-radius my-4"
      >
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                DOING THE WORK
              </h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="p-6 font-size-16">
          <table class="width-100">
            <tbody v-if="emailJobTemplates.length > 0">
              <template v-for="(template, index) in emailJobTemplates">
                <tr
                  :key="'title-' + index"
                  :class="{ 'custom-border-top': index > 0 }"
                >
                  <td
                    colspan="2"
                    class="pb-4 font-weight-600 font-size-16 color-custom-blue"
                    :class="{ 'py-4': index > 0 }"
                  >
                    {{ template.template }}

                    <v-chip
                      class="ma-2"
                      :color="template.activated ? 'green' : 'red lighten-1'"
                      label
                      outlined
                      small
                    >
                      <template v-if="template.activated">Active</template>
                      <template v-else>In-Active</template>
                    </v-chip>
                  </td>
                </tr>
                <tr :key="'description-' + index">
                  <td>{{ template.description_text }}</td>
                  <td class="text-right pb-4">
                    <v-btn
                      text
                      v-on:click="pushToUpdate(template.id)"
                      color="cyan"
                      class="font-size-16"
                      >Edit</v-btn
                    >
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot v-else>
              <template v-if="pageLoading">
                <tr>
                  <td colspan="2">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-200px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
                <tr>
                  <td width="100%">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-300px"
                    ></v-skeleton-loader>
                  </td>
                  <td class="text-right">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-100px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="2">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no email template at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tfoot>
          </table>
        </v-card-text>
      </v-card>
      <v-card
        v-if="false"
        flat
        class="custom-grey-border remove-border-radius my-4"
      >
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                GETTING PAID
              </h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="p-6 font-size-16">
          <table class="width-100">
            <tbody v-if="emailPaymentTemplates.length > 0">
              <template v-for="(template, index) in emailPaymentTemplates">
                <tr
                  :key="'title-' + index"
                  :class="{ 'custom-border-top': index > 0 }"
                >
                  <td
                    colspan="2"
                    class="pb-4 font-weight-600 font-size-16 color-custom-blue"
                    :class="{ 'py-4': index > 0 }"
                  >
                    {{ template.template }}

                    <v-chip
                      class="ma-2"
                      :color="template.activated ? 'green' : 'red lighten-1'"
                      label
                      outlined
                      small
                    >
                      <template v-if="template.activated">Active</template>
                      <template v-else>In-Active</template>
                    </v-chip>
                  </td>
                </tr>
                <tr :key="'description-' + index">
                  <td>{{ template.description_text }}</td>
                  <td class="text-right pb-4">
                    <v-btn
                      text
                      v-on:click="pushToUpdate(template.id)"
                      color="cyan"
                      class="font-size-16"
                      >Edit</v-btn
                    >
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot v-else>
              <template v-if="pageLoading">
                <tr>
                  <td colspan="2">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-200px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
                <tr>
                  <td width="100%">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-300px"
                    ></v-skeleton-loader>
                  </td>
                  <td class="text-right">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-100px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="2">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no email template at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tfoot>
          </table>
        </v-card-text>
      </v-card>
      <v-card
        v-if="false"
        flat
        class="custom-grey-border remove-border-radius my-4"
      >
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                DOCUMENT MANAGEMENT
              </h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="p-6 font-size-16">
          <table class="width-100">
            <tbody v-if="emailDocumentTemplates.length > 0">
              <template v-for="(template, index) in emailDocumentTemplates">
                <tr
                  :key="'title-' + index"
                  :class="{ 'custom-border-top': index > 0 }"
                >
                  <td
                    colspan="2"
                    class="pb-4 font-weight-600 font-size-16 color-custom-blue"
                    :class="{ 'py-4': index > 0 }"
                  >
                    {{ template.template }}

                    <v-chip
                      class="ma-2"
                      :color="template.activated ? 'green' : 'red lighten-1'"
                      label
                      outlined
                      small
                    >
                      <template v-if="template.activated">Active</template>
                      <template v-else>In-Active</template>
                    </v-chip>
                  </td>
                </tr>
                <tr :key="'description-' + index">
                  <td>{{ template.description_text }}</td>
                  <td class="text-right pb-4">
                    <v-btn
                      text
                      v-on:click="pushToUpdate(template.id)"
                      color="cyan"
                      class="font-size-16"
                      >Edit</v-btn
                    >
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot v-else>
              <template v-if="pageLoading">
                <tr>
                  <td colspan="2">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-200px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
                <tr>
                  <td width="100%">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-300px"
                    ></v-skeleton-loader>
                  </td>
                  <td class="text-right">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-100px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="2">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no email template at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tfoot>
          </table>
        </v-card-text>
      </v-card>
      <v-card
        v-if="emailUserTemplates.length > 0"
        flat
        class="custom-grey-border remove-border-radius my-4"
      >
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                USER NOTIFICATIONS
              </h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="p-6 font-size-16">
          <table class="width-100">
            <tbody v-if="emailUserTemplates.length > 0">
              <template v-for="(template, index) in emailUserTemplates">
                <tr
                  :key="'title-' + index"
                  :class="{ 'custom-border-top': index > 0 }"
                >
                  <td
                    colspan="2"
                    class="pb-4 font-weight-600 font-size-16 color-custom-blue"
                    :class="{ 'py-4': index > 0 }"
                  >
                    {{ template.template }}

                    <v-chip
                      class="ma-2"
                      :color="template.activated ? 'green' : 'red lighten-1'"
                      label
                      outlined
                      small
                    >
                      <template v-if="template.activated">Active</template>
                      <template v-else>In-Active</template>
                    </v-chip>
                  </td>
                </tr>
                <tr :key="'description-' + index">
                  <td>{{ template.description_text }}</td>
                  <td class="text-right pb-4">
                    <v-btn
                      text
                      v-on:click="pushToUpdate(template.id)"
                      color="cyan"
                      class="font-size-16"
                      >Edit</v-btn
                    >
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot v-else>
              <template v-if="pageLoading">
                <tr>
                  <td colspan="2">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-200px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
                <tr>
                  <td width="100%">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-300px"
                    ></v-skeleton-loader>
                  </td>
                  <td class="text-right">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-100px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="2">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no email template at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tfoot>
          </table>
        </v-card-text>
      </v-card>
      <v-card
        v-if="emailCustomerTemplates.length > 0"
        flat
        class="custom-grey-border remove-border-radius my-4"
      >
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="12" class="py-0 my-auto">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                CUSTOMER NOTIFICATIONS
              </h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="p-6 font-size-16">
          <table class="width-100">
            <tbody v-if="emailCustomerTemplates.length > 0">
              <template v-for="(template, index) in emailCustomerTemplates">
                <tr
                  :key="'title-' + index"
                  :class="{ 'custom-border-top': index > 0 }"
                >
                  <td
                    colspan="2"
                    class="pb-4 font-weight-600 font-size-16 color-custom-blue"
                    :class="{ 'py-4': index > 0 }"
                  >
                    {{ template.template }}

                    <v-chip
                      class="ma-2"
                      :color="template.activated ? 'green' : 'red lighten-1'"
                      label
                      outlined
                      small
                    >
                      <template v-if="template.activated">Active</template>
                      <template v-else>In-Active</template>
                    </v-chip>
                  </td>
                </tr>
                <tr :key="'description-' + index">
                  <td>{{ template.description_text }}</td>
                  <td class="text-right pb-4">
                    <v-btn
                      text
                      v-on:click="pushToUpdate(template.id)"
                      color="cyan"
                      class="font-size-16"
                      >Edit</v-btn
                    >
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot v-else>
              <template v-if="pageLoading">
                <tr>
                  <td colspan="2">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-200px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
                <tr>
                  <td width="100%">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-300px"
                    ></v-skeleton-loader>
                  </td>
                  <td class="text-right">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-100px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="2">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no email template at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tfoot>
          </table>
        </v-card-text>
      </v-card>
      <v-card
        v-if="emailPurchaseOrderTemplates.length > 0"
        flat
        class="custom-grey-border remove-border-radius my-4"
      >
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="12" class="py-0 my-auto">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Purchase Order Notifications
              </h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="p-6 font-size-16">
          <table class="width-100">
            <tbody v-if="emailPurchaseOrderTemplates.length > 0">
              <template
                v-for="(template, index) in emailPurchaseOrderTemplates"
              >
                <tr
                  :key="'title-' + index"
                  :class="{ 'custom-border-top': index > 0 }"
                >
                  <td
                    colspan="2"
                    class="pb-4 font-weight-600 font-size-16 color-custom-blue"
                    :class="{ 'py-4': index > 0 }"
                  >
                    {{ template.template }}

                    <v-chip
                      class="ma-2"
                      :color="template.activated ? 'green' : 'red lighten-1'"
                      label
                      outlined
                      small
                    >
                      <template v-if="template.activated">Active</template>
                      <template v-else>In-Active</template>
                    </v-chip>
                  </td>
                </tr>
                <tr :key="'description-' + index">
                  <td>{{ template.description_text }}</td>
                  <td class="text-right pb-4">
                    <v-btn
                      text
                      v-on:click="pushToUpdate(template.id)"
                      color="cyan"
                      class="font-size-16"
                      >Edit</v-btn
                    >
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot v-else>
              <template v-if="pageLoading">
                <tr>
                  <td colspan="2">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-200px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
                <tr>
                  <td width="100%">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-300px"
                    ></v-skeleton-loader>
                  </td>
                  <td class="text-right">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-100px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="2">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no email template at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tfoot>
          </table>
        </v-card-text>
      </v-card>
      <v-card v-if="false" flat class="custom-grey-border remove-border-radius">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                TASK NOTIFICATIONS
              </h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="p-6 font-size-16">
          <table class="width-100">
            <tbody v-if="emailTaskTemplates.length > 0">
              <template v-for="(template, index) in emailTaskTemplates">
                <tr
                  :key="'title-' + index"
                  :class="{ 'custom-border-top': index > 0 }"
                >
                  <td
                    colspan="2"
                    class="pb-4 font-weight-600 font-size-16 color-custom-blue"
                    :class="{ 'py-4': index > 0 }"
                  >
                    {{ template.template }}

                    <v-chip
                      class="ma-2"
                      :color="template.activated ? 'green' : 'red lighten-1'"
                      label
                      outlined
                      small
                    >
                      <template v-if="template.activated">Active</template>
                      <template v-else>In-Active</template>
                    </v-chip>
                  </td>
                </tr>
                <tr :key="'description-' + index">
                  <td>{{ template.description_text }}</td>
                  <td class="text-right pb-4">
                    <v-btn
                      text
                      v-on:click="pushToUpdate(template.id)"
                      color="cyan"
                      class="font-size-16"
                      >Edit</v-btn
                    >
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot v-else>
              <template v-if="pageLoading">
                <tr>
                  <td colspan="2">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-200px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
                <tr>
                  <td width="100%">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-300px"
                    ></v-skeleton-loader>
                  </td>
                  <td class="text-right">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-100px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="2">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no email template at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tfoot>
          </table>
        </v-card-text>
      </v-card>
    </perfect-scrollbar>
  </v-container>
</template>

<script>
import { GET, PATCH } from "@/core/services/store/request.module";

export default {
  name: "profile-service-setting",
  data() {
    return {
      pageLoading: true,
      templateLoading: true,
      template: 0,
      emailLeadTemplates: new Array(),
      emailPackageTemplates: new Array(),
      emailQuotationTemplates: new Array(),
      emailPaymentTemplates: new Array(),
      emailUserTemplates: new Array(),
      emailJobTemplates: new Array(),
      emailCustomerTemplates: new Array(),
      emailPurchaseOrderTemplates: new Array(),
      emailTaskTemplates: new Array(),
      emailDocumentTemplates: new Array(),
    };
  },
  methods: {
    pushToUpdate(id) {
      this.$router.push(
        this.getDefaultRoute("email.template.update", {
          params: { id },
        })
      );
    },
    getEmailTemplateSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/email-template" })
        .then(({ data }) => {
          _this.emailLeadTemplates = _this.lodash.filter(data, function (row) {
            return row.group == "lead";
          });

          _this.emailJobTemplates = _this.lodash.filter(data, function (row) {
            return row.group == "job";
          });
          _this.emailPackageTemplates = _this.lodash.filter(
            data,
            function (row) {
              return row.group == "package";
            }
          );
          _this.emailQuotationTemplates = _this.lodash.filter(
            data,
            function (row) {
              return row.group == "quotation";
            }
          );
          _this.emailPaymentTemplates = _this.lodash.filter(
            data,
            function (row) {
              return row.group == "payment";
            }
          );
          _this.emailUserTemplates = _this.lodash.filter(data, function (row) {
            return row.group == "user";
          });
          _this.emailCustomerTemplates = _this.lodash.filter(
            data,
            function (row) {
              return row.group == "customer";
            }
          );

          _this.emailPurchaseOrderTemplates = _this.lodash.filter(
            data,
            function (row) {
              return row.group == "purchaseorder";
            }
          );
          _this.emailTaskTemplates = _this.lodash.filter(data, function (row) {
            return row.group == "task";
          });
          _this.emailDocumentTemplates = _this.lodash.filter(
            data,
            function (row) {
              return row.group == "document";
            }
          );
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateStatusEmailTemplateSetting() {
      const _this = this;
      _this.pageLoading = true;
      let status = 1;
      _this.$store
        .dispatch(PATCH, {
          url: "setting/email-template/" + _this.template + "/status/" + status,
          data: _this.setting,
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.getEmailTemplateSetting();
  },
};
</script>
